import { DocumentVerificationDecisionUserTaskExtension, Nx3Api, type DetailEvent } from '@nx3/nx3-core-ui';

export class CustomDocumentVerificationDecisionUserTaskExtension extends DocumentVerificationDecisionUserTaskExtension {
  constructor(public nx3: Nx3Api) {
    super(nx3);
    this.showDocumentValues = true;
    this.showCarrierValues = true;
    this.updateVerification = true;
    this.euLicenceDocumenttype = 'license';
  }

  onDetailInit(event: DetailEvent): void {
    super.onDetailInit(event);
    if (!this.documentValidFromToHidden) {
      event.component.metadata.getFieldByName('documentValidFrom').required = false;
      event.component.metadata.getFieldByName('documentValidTo').required = false;
      event.component.metadata.getFieldByName('documentValidFrom').hidden = true;
      event.component.metadata.getFieldByName('documentValidTo').hidden = true;

      event.component.metadata.form.sections[0].rows[1].fields.push(
        event.component.metadata.form.sections[0].rows[0].fields[2]
      );

      delete event.component.metadata.form.sections[0].rows[0].fields[2];
    }
  }
}
